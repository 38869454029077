import React from 'react'
import { kebabCase } from 'lodash'
import { Helmet } from 'react-helmet'
import { Link, graphql } from 'gatsby'
import Layout from '../components/layout'
import Seo from "../components/seo"

const AuthorPage = ({
  data: {
    allMarkdownRemark: { group },
    site: {
      siteMetadata: { title },
    },
  },
}) => (
  <Layout>
    <div className="container">
    <Seo title={`Authors | ${title}`} />
      <Helmet title={`Authors | ${title}`} />        
          <div
            className="column is-10 is-offset-1"
            style={{ marginBottom: '6rem' }}
          >
          <section className="section">
            <h1 className="title is-size-2 is-bold-light">Authors</h1>
            <ul className="taglist">
              {group.map((author) => (
                <li key={author.fieldValue}>
                  <Link to={`/author/${kebabCase(author.fieldValue)}/`}>
                    {author.fieldValue} ({author.totalCount})
                  </Link>
                </li>
              ))}
            </ul>
          </section>
      </div>        
    </div>
  </Layout>
)

export default AuthorPage

export const authorPageQuery = graphql`
  query AuthorQuery {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(limit: 1000) {
      group(field: frontmatter___author) {
        fieldValue
        totalCount
      }
    }
  }
`
